import Vue from 'vue'
import Vuex from 'vuex'

import user from './user'
import dashboard from './dashboard'
import { MAX_DAYS_OF_RESULTS } from '../constants'

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',

  state: {
    packageVersion: process.env.PACKAGE_VERSION || '0',
    windowWidth: window.innerWidth,
  },

  actions: {
    init () {
      // Implement this root action in module
    },
  },

  getters: {
    appVersion: (state) => {
      return state.packageVersion
    },

    isSmallScreen: state => state.windowWidth < 768,
    isBigScreen: state => state.windowWidth > 767,

    maxGraphValues: state => Math.min(Math.round(state.windowWidth / 11.5), MAX_DAYS_OF_RESULTS),
  },

  mutations: {
    setWindowWidth(state, value) {
      state.windowWidth = value
    },
  },

  modules: {
    user,
    dashboard,
  },
})

export default store
