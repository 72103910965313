import { AUTH_USER_ID_LS_KEY } from '../constants'
import { getSubdomain } from './host'

const userLocalStorage = {
  userID: localStorage[AUTH_USER_ID_LS_KEY],

  userKey: (key) => {
    const subdomain = getSubdomain()
    return `${subdomain}-${userLocalStorage.userID}-${key}`
  },

  init: () => {
    userLocalStorage.userID = localStorage[AUTH_USER_ID_LS_KEY]
  },

  setItem: (key, value) => {
    userLocalStorage.init()
    const userKey = userLocalStorage.userKey(key)
    return localStorage.setItem(userKey, value)
  },

  getItem: (key) => {
    userLocalStorage.init()
    const userKey = userLocalStorage.userKey(key)
    return localStorage.getItem(userKey)
  },

  removeItem: (key) => {
    userLocalStorage.init()
    const userKey = userLocalStorage.userKey(key)
    return localStorage.removeItem(userKey)
  },

  clear: () => {
    userLocalStorage.init()
    const keys = Object.keys(localStorage).filter(key => key.startsWith(userLocalStorage.userKey('')))
    keys.forEach(key => localStorage.removeItem(key))
  },

}

export default userLocalStorage