/*
 * This file is picked up by the build system only
 * when building for PRODUCTION
 */
import { Loading, Notify } from 'quasar'
import { register } from 'register-service-worker'

register(process.env.SERVICE_WORKER_FILE, {
  ready () {
    console.log('App is being served from cache by a service worker.')
  },
  cached (registration) { // registration -> a ServiceWorkerRegistration instance
    console.log('Content has been cached for offline use.')
  },
  updated (registration) { // registration -> a ServiceWorkerRegistration instance
    // notify user that there is an update
    Loading.hide() //workaround to be able to click the notification inmediatly
    Notify.create({
      message: 'Er is een nieuwe versie van Tipper beschikbaar.',
      icon: 'cloud_download',
      type: 'info',
      closeBtn: 'Tipper bijwerken',
      timeout: 0,
      onDismiss() {
        location.reload(true)
      },
    })
  },
  offline () {
    console.log('No internet connection found. App is running in offline mode.')
  },
  error (err) {
    console.error('Error during service worker registration:', err)
  },
})

// ServiceWorkerRegistration: https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration
