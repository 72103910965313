import { date as DateUtils } from 'quasar'

import { selectedItemsToString } from './string-utils'

export const formatDateShort = (date) => {
  return DateUtils.formatDate(date, 'DD-MM-YYYY').toLowerCase()
}

export function dayOfWeekToString(key) {
  const americanWeekDayIndex = parseInt(key, 10)
  switch (americanWeekDayIndex) {
    case 1:
      return 'Zondag'
    case 2:
      return 'Maandag'
    case 3:
      return 'Dinsdag'
    case 4:
      return 'Woensdag'
    case 5:
      return 'Donderdag'
    case 6:
      return 'Vrijdag'
    case 7:
      return 'Zaterdag'
    default:
      return 'Onbekend'
  }
}

export function dayOfWeekToShortString(key) {
  const americanWeekDayIndex = parseInt(key, 10)
  switch (americanWeekDayIndex) {
    case 1:
      return 'Zo'
    case 2:
      return 'Ma'
    case 3:
      return 'Di'
    case 4:
      return 'Woe'
    case 5:
      return 'Do'
    case 6:
      return 'Vr'
    case 7:
      return 'Za'
    default:
      return '?'
  }
}

export function monthToString(key) {
  const monthIndex = parseInt(key, 10)
  switch (monthIndex) {
    case 1:
      return 'Januari'
    case 2:
      return 'Februari'
    case 3:
      return 'Maart'
    case 4:
      return 'April'
    case 5:
      return 'Mei'
    case 6:
      return 'Juni'
    case 7:
      return 'Juli'
    case 8:
      return 'Augustus'
    case 9:
      return 'September'
    case 10:
      return 'Oktober'
    case 11:
      return 'November'
    case 12:
      return 'December'
    default:
      return `Onbekend (${monthIndex})`
  }
}

export function monthToShortString(key) {
  const monthIndex = parseInt(key, 10)
  switch (monthIndex) {
    case 1:
      return 'Jan'
    case 2:
      return 'Feb'
    case 3:
      return 'Maa'
    case 4:
      return 'Apr'
    case 5:
      return 'Mei'
    case 6:
      return 'Jun'
    case 7:
      return 'Jul'
    case 8:
      return 'Aug'
    case 9:
      return 'Sep'
    case 10:
      return 'Okt'
    case 11:
      return 'Nov'
    case 12:
      return 'Dec'
    default:
      return `${monthIndex}`
  }
}

export function toSimpleDate(date) {
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset())
  return date.toJSON().slice(0, 10)
}

/**
 * To send a date only to the backend --> convert to format YYYY-MM-DD
 * @param {Date} date date to convert
 * TODO has the same result as above function!
 */
export function toGraphQLDateOnly(date) {
  //return DateUtils.adjustDate(date, { minutes: -date.getTimezoneOffset(), milliseconds: 0 })
  return DateUtils.formatDate(date, 'YYYY-MM-DD')
}

export function getDateOfISOWeek(w, y) {
  const simple = new Date(y, 0, 1 + (w - 1) * 7)
  const dow = simple.getDay()
  const ISOweekStart = simple
  if (dow <= 4) {
    ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1)
  } else {
    ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay())
  }
  // add offset to make it UTC compatible
  ISOweekStart.setTime(ISOweekStart.getTime() - ISOweekStart.getTimezoneOffset() * 60000)
  return ISOweekStart
}

export function getWeeksInPeriod(dateFrom, dateTo) {
  const result = []
  const numberOfDays = dateTo != null ? DateUtils.getDateDiff(dateTo, dateFrom, 'days') : 365
  for (let i = 0; i < numberOfDays + 6; i += 7) {
    const currentDate = DateUtils.addToDate(dateFrom, { days: i })
    const year = currentDate.getFullYear()
    const week = DateUtils.getWeekOfYear(currentDate)
    const from = getDateOfISOWeek(week, year)
    const to = DateUtils.addToDate(from, { days: 6 })
    result.push({ year, week, from, to })
  }
  return result
}

export function getMonthYearsInPeriod(dateFrom, dateTo) {
  const result = []
  const numerOfMonths = dateTo != null ? DateUtils.getDateDiff(dateTo, dateFrom, 'months') : 12
  for (let i = 0; i < numerOfMonths + 1; i += 1) {
    const currentDate = DateUtils.addToDate(dateFrom, { month: i })
    const year = currentDate.getFullYear()
    const month = currentDate.getMonth()
    const from = new Date(Date.UTC(year, month, 1))
    const to = new Date(Date.UTC(year, month, DateUtils.daysInMonth(currentDate)))
    result.push({ year, month, from, to })
  }
  return result
}

export function getYearsInPeriod(dateFrom, dateTo) {
  const result = []
  if (dateTo == null) {
    dateTo = new Date()
  }
  const numberOfYears = DateUtils.getDateDiff(dateTo, dateFrom, 'years')
  for (let i = 0; i < numberOfYears + 1; i += 1) {
    result.push(new Date(dateFrom).getFullYear() + i)
  }
  return result
}

// get day of week starts logical with sunday = 0 but we use a retarted order where 1 is sunday
export function getRetartedDayOfWeek(date) {
  const normalDayIndex = DateUtils.getDayOfWeek(date)
  return normalDayIndex + 1 > 7 ? 1 : normalDayIndex + 1
}

/**
 * Get the number of days in a period
 * @param {Date} dateFrom
 * @param {Date} dateTo
 * @param {Array} daysOfWeek
 * @returns count of days
 */
export function getLengthInDays(dateFrom, dateTo, daysOfWeek = []) {
  const daysOfWeekUnique = daysOfWeek != null ? [...new Set(daysOfWeek)] : []
  if (daysOfWeekUnique.length === 0 || daysOfWeekUnique.length === 7) {
    return DateUtils.getDateDiff(dateTo, dateFrom, 'days') + 1
  } else {
    let count = 0
    // eslint-disable-next-line no-unmodified-loop-condition
    for (let dt = new Date(dateFrom); dt <= new Date(dateTo); dt.setDate(dt.getDate() + 1)) {
      const date = new Date(dt)
      // at dayjs sunday = 0, at our stupid logic sunday = 1 (i wish monday was 0)
      if (daysOfWeekUnique.includes(getRetartedDayOfWeek(date))) {
        count++
      }
    }
    return count
  }
}

/**
 * Validate if string is a valid date DD-MM-YYYY
 * @param {String} s
 */
export function isDutchDate(s) {
  const match = s.match(/^([0-9]{2})-([0-9]{2})-([0-9]{4})$/)
  if (!match || match.length !== 4) {
    return false
  }
  const test = new Date(match[3], match[2] - 1, match[1])
  return (
    (test.getDate() === parseInt(match[1])) &&
    (test.getMonth() === parseInt(match[2] - 1)) &&
    (test.getFullYear() === parseInt(match[3]))
  )
}

export function dutchToDate(s) {
  const match = s.match(/^([0-9]{2})-([0-9]{2})-([0-9]{4})$/)
  if (!match || match.length !== 4) {
    return null
  }

  return new Date(match[3], match[2] - 1, match[1])
}

export function selectedWeekDaysToString(allSelectedText, selectedValues) {
  if (selectedValues.length === 0) {
    return 'Geen dagen geselecteerd'
  }

  // weekdagen
  if (selectedValues.toString() === [2, 3, 4, 5, 6].toString()) return 'Doordeweeks'
  // weekends
  if (selectedValues.toString() === [7, 1].toString()) return 'Weekenden'

  return selectedItemsToString(allSelectedText, selectedValues,
    7, selectedValues.length > 2 ? dayOfWeekToShortString : dayOfWeekToString)
}

export function currentWeek() {
  const now = new Date()
  return Number.parseInt(DateUtils.formatDate(now, 'w')) // TODO: SHOULD BE ISO WEEK!!
}

export function lastWeekPeriod() {
  const currentYear = new Date().getFullYear()
  const dateFrom = toSimpleDate(getDateOfISOWeek(currentWeek() - 1, currentYear))
  const dateTo = toSimpleDate(DateUtils.addToDate(dateFrom, { days: 6 }))
  return { dateFrom, dateTo }
}