/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.conf.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/

import lang from 'quasar/lang/nl'


import Vue from 'vue'

import {Quasar,QLayout,QHeader,QFooter,QDrawer,QPageContainer,QPage,QToolbar,QToolbarTitle,QBtn,QIcon,QList,QItemLabel,QItem,QItemSection,QInput,QField,QSelect,QCheckbox,QDialog,QStepper,QStep,QStepperNavigation,QBtnGroup,QTabs,QTab,QRouteTab,QPageSticky,QSlider,QDate,QTime,QTooltip,QTable,QTh,QTr,QTd,QChip,QCarousel,QCarouselSlide,QCarouselControl,QExpansionItem,QSpinnerCube,QScrollArea,QBtnDropdown,QUploader,QLinearProgress,QCard,QSeparator,QCardActions,QBanner,Ripple,Notify,Loading,Dialog} from 'quasar'


Vue.use(Quasar, { config: {},lang: lang,components: {QLayout,QHeader,QFooter,QDrawer,QPageContainer,QPage,QToolbar,QToolbarTitle,QBtn,QIcon,QList,QItemLabel,QItem,QItemSection,QInput,QField,QSelect,QCheckbox,QDialog,QStepper,QStep,QStepperNavigation,QBtnGroup,QTabs,QTab,QRouteTab,QPageSticky,QSlider,QDate,QTime,QTooltip,QTable,QTh,QTr,QTd,QChip,QCarousel,QCarouselSlide,QCarouselControl,QExpansionItem,QSpinnerCube,QScrollArea,QBtnDropdown,QUploader,QLinearProgress,QCard,QSeparator,QCardActions,QBanner},directives: {Ripple},plugins: {Notify,Loading,Dialog} })
