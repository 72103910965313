function getSubdomain() {
  const hostParts = window.location.host.split('.')
  const subdomain = hostParts.length > 2 ? hostParts[0] : ''
  if (subdomain === 'www') return ''
  return subdomain
}

function isLocalhost() {
  return location.hostname === 'localhost' || location.hostname === '127.0.0.1'
}

export { getSubdomain, isLocalhost }