import { date } from 'quasar'
import {
  toSimpleDate, getWeeksInPeriod, getMonthYearsInPeriod,
  monthToString, monthToShortString, getLengthInDays,
} from './date-utils'

let periodsMap = null

const recentPeriodsInDays = [60, 30, 14, 7, 1]

function addRecentPeriods(rounds) {
  const dateTo = new Date()
  for (const round of rounds) {
    let end = round.end !== null ? new Date(round.end) : null
    if (end === null || end > new Date()) {
      end = new Date()
    }

    for (const days of recentPeriodsInDays) {
      const dateFrom = date.addToDate(dateTo, { days: -(days - 1) })
      if (dateFrom < end) {
        const key = periodUtils.getKey('C', dateFrom, dateTo)
        if (!periodsMap.has(key)) {
          const label = days > 1 ? `Laatste ${days} dagen` : 'Gisteren'
          periodsMap.set(key, {
            label,
            shortLabel: label,
            roundId: null,
            inRounds: [round],
            type: 'recent',
            typeLabel: 'Recent',
            dateFrom: toSimpleDate(dateFrom),
            dateTo: toSimpleDate(dateTo),
          })
        } else {
          periodsMap.get(key).inRounds.push(round)
        }
      }
    }
  }
}

function addRoundPeriods(rounds) {
  for (const round of rounds) {
    const key = periodUtils.getKey('R', round.start, round.end)
    if (!periodsMap.has(key)) {
      periodsMap.set(key, {
        label: round.name,
        shortLabel: round.name,
        roundId: round.id,
        type: 'round',
        typeLabel: 'Gehele meetronde',
        dateFrom: round.start,
        dateTo: round.end,
      })
    }
  }
}

function addWeekPeriods(rounds) {
  for (const round of rounds) {
    let end = round.end !== null ? new Date(round.end) : null
    if (end === null || end > new Date()) {
      end = new Date()
    }

    for (const week of getWeeksInPeriod(round.start, end)) {
      const key = periodUtils.getKey('W', week.from, week.to)
      if (!periodsMap.has(key)) {
        const label = `Week ${week.week} - ${week.year}`
        const shortLabel = `W${week.week} ${week.year}` //TODO not add year if it is this year?
        periodsMap.set(key, {
          label,
          shortLabel,
          roundId: null,
          inRounds: [round],
          type: 'week',
          typeLabel: `${date.formatDate(week.from, 'D-MMM').toLowerCase()} t/m ${date.formatDate(week.to, 'D-MMM').toLowerCase()} `,
          dateFrom: toSimpleDate(week.from),
          dateTo: toSimpleDate(week.to),
        })
      } else {
        periodsMap.get(key).inRounds.push(round)
      }
    }
  }
}

function addMonthPeriods(rounds) {
  for (const round of rounds) {
    let end = round.end !== null ? new Date(round.end) : null
    if (end === null || end > new Date()) {
      end = new Date()
    }

    for (const month of getMonthYearsInPeriod(round.start, end)) {
      const key = periodUtils.getKey('M', month.from, month.to)
      if (!periodsMap.has(key)) {
        const logicalMonth = month.month + 1
        const label = `${monthToString(logicalMonth)} - ${month.year}`
        const shortLabel = `${monthToShortString(logicalMonth)} ${month.year}` //TODO not add year if it is this year?
        periodsMap.set(key, {
          label,
          shortLabel,
          roundId: null,
          inRounds: [round],
          type: 'month',
          typeLabel: 'Maand',
          dateFrom: toSimpleDate(month.from),
          dateTo: toSimpleDate(month.to),
        })
      } else {
        periodsMap.get(key).inRounds.push(round)
      }
    }
  }
}

function getNewSelectedKey(oldKeys, newKeys) {
  const difference = newKeys.filter(d => !oldKeys.includes(d))
  if (difference.length === 0) return null
  return difference[0]
}

function addMissingSelectedPeriods(selectedKeys) {
  if (selectedKeys === null) return

  // add current selected options if they are not in the list
  for (const key of selectedKeys) {
    if (!periodsMap.has(key)) {
      const arr = key.split(':')
      if (arr.length !== 3) break

      try {
        const period = periodUtils.keyToPeriod(key)
        if (period) {
          periodsMap.set(key, period)
        }
      } catch (e) {
        console.error(`Invalid period key ${key}`)
      }
    }
  }
}

const periodUtils = {
  /**
   * Format of period keys is type prefix + dateFrom + dateTo
   * f.e. W-20200928-20201004 is Week 40 2020
   *
   * prefixes:
   * --------
   * R: round, W: week, M: month, C: custom
   *
   * @param {*} rounds
   */
  initPeriods: function(rounds, selectedKeys = null) {
    periodsMap = new Map()
    addMonthPeriods(rounds)
    addWeekPeriods(rounds)
    addRecentPeriods(rounds)
    addRoundPeriods(rounds)

    periodsMap = new Map(Array.from(periodsMap).reverse())

    addMissingSelectedPeriods(selectedKeys)

    return periodsMap
  },

  getPeriods: function() {
    return periodsMap
  },

  getPeriodTypes() {
    return [
      { key: 'recent', label: 'Recent', labelPlural: 'Recent' },
      { key: 'round', label: 'Meetronde', labelPlural: 'Meetrondes' },
      { key: 'month', label: 'Maand', labelPlural: 'Maanden' },
      { key: 'week', label: 'Week', labelPlural: 'Weken' },
    ]
  },

  getPeriodTypeLabel(key) {
    const periodType = this.getPeriodTypes().filter(x => x.key === key)
    return periodType !== null ? periodType.label : null
  },

  getKey(prefix, dateFrom, dateTo) {
    if (prefix === 'C') {
      // recent
      const x = date.getDateDiff(dateTo, dateFrom) + 1
      return `${prefix}:${x}:days`
    }

    if (dateFrom instanceof Date) {
      dateFrom = toSimpleDate(dateFrom)
    }
    if (dateTo instanceof Date) {
      dateTo = toSimpleDate(dateTo)
    }
    return `${prefix}:${dateFrom}:${dateTo}`
  },

  keyToPeriod(key) {
    const period = periodsMap.get(key)
    if (period) {
      return period
    } else {
      // period is not a preset anymore
      // try to parse it
      const periodArr = key.split(':')

      if (periodArr.length !== 3) {
        return null
      } else {
        if (periodArr[0] === 'C') {
          const dateMutation = {}
          // format is: C:x:days f.e.
          const days = periodArr[1]
          dateMutation[periodArr[2]] = -(parseInt(days) - 1)
          const label = days > 1 ? `Laatste ${days} dagen` : 'Gisteren'
          return {
            dateFrom: toSimpleDate(date.addToDate(new Date(), dateMutation)),
            dateTo: toSimpleDate(new Date()),
            label,
            type: 'recent',
            typeLabel: 'Recent',
            shortLabel: label,
          }
        }

        const dateFrom = periodArr[1]
        const dateTo = periodArr[2] === 'null' ? null : periodArr[2]
        const dateToText = dateTo == null ? 'vandaag' : date.formatDate(dateTo, 'D-M-YYYY')
        const dateToShortText = dateTo == null ? 'vandaag' : date.formatDate(dateTo, 'D-M-YY')

        return {
          label: `${date.formatDate(dateFrom, 'D-M-YYYY')} t/m ${dateToText}`,
          shortLabel: `${date.formatDate(dateFrom, 'D-M-YY')} t/m ${dateToShortText}`,
          type: 'custom',
          typeLabel: 'Aangepast',
          dateFrom,
          dateTo,
        }
      }
    }
  },

  isLoaded() {
    return periodsMap !== null
  },

  isPeriodInRound(round, dateFrom, dateTo = null) {
    return round.start <= dateFrom && (dateTo == null || round.dateTo == null || round.dateTo >= dateTo)
  },

  isPeriodKeyInRound(round, key) {
    const period = this.keyToPeriod(key)
    return this.isPeriodInRound(round, period.dateFrom, period.dateTo)
  },

  /**
   * When the user selects a new period that is more specific than an existed selected period,
   * remove the periods that totally overlap
   *
   * @param {*} oldKeys the previous selection
   * @param {*} newKeys the new selection
   */
  getPeriodKeysTheUserWants(oldKeys, newKeys) {
    if (newKeys === null || oldKeys === null ||
        oldKeys.length > newKeys.length) {
      return newKeys
    }

    const newSelectedKey = getNewSelectedKey(oldKeys, newKeys)

    const smartKeys = [newSelectedKey]

    if (newSelectedKey === null) {
      // nothing new selected (maybe something is unselected)
      return newKeys
    }
    const newPeriod = periodsMap.get(newSelectedKey)

    for (const key of oldKeys) {
      if (newSelectedKey !== key) {
        const period = periodsMap.get(key)

        if (!period) {
          continue
        }
        // check if newPeriod is completly within this period
        // TODO andersom misschien ook: niet compleet overlappend: experimenteren!
        if (new Date(newPeriod.dateFrom) >= new Date(period.dateFrom) &&
          // eslint-disable-next-line no-empty
          (period.dateTo === null || newPeriod.dateTo === null || new Date(newPeriod.dateTo) <= new Date(period.dateTo))) {
        } else {
          // check if old period is completly within the new period
          if (new Date(period.dateFrom) >= new Date(newPeriod.dateFrom) &&
            (period.dateTo === null || newPeriod.dateTo === null || new Date(period.dateTo) <= new Date(newPeriod.dateTo))) {
            // do nothing
          } else {
            smartKeys.push(key)
          }
        }
      }
    }
    //smartKeys.sort()
    return smartKeys
  },

  /**
   * Get the lenght of a period by its key
   * @param {String} periodKey period identifier from the period list
   * @param {Array} daysOfWeek the days of week you want to include (leave empty for all days; 1-indexed; starts witn sunday)
   * @returns the number of days it takes
   */
  getLengthInDaysOrPeriod(periodKey, daysOfWeek = []) {
    const period = this.keyToPeriod(periodKey)
    if (period == null) return null
    const dateTo = period.dateTo != null ? period.dateTo : new Date()
    return getLengthInDays(period.dateFrom, dateTo, daysOfWeek)
  },

  /**
   * Check if a certain date is within an array of periods
   * @param {Date} date the date to check
   * @param {Array} periods periods with a dateFrom and a dateTo property
   * @returns true if date falls in periods
   */
  isDateInPeriods(date, periods) {
    for (const period of periods) {
      if (date >= new Date(period.dateFrom) && (period.dateTo == null || date <= new Date(period.dateTo))) {
        return true
      }
    }
    return false
  },
}

export default periodUtils