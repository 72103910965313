import store from '../store/index'

const localStore = store

const urlQueryToObject = (query) => {
  if (query.length === 0) {
    return undefined
  }
  const object = {}
  for (const prop in query) {
    try {
      object[prop] = JSON.parse(query[prop])
    } catch {
      object[prop] = query[prop]
    }
  }
  return object
}

export default [
  // intro (not logged in)
  {
    path: '/',
    component: () => import('layouts/intro'),
    meta: { requiresAuth: false },
    children: [
      {
        path: '',
        name: 'intro',
        component: () => import('pages/intro'),
        meta: { ifAuthGoHome: true },
      },
      {
        path: 'privacy',
        name: 'privacy',
        component: () => import('pages/privacy-statement'),
      },
    ],
  },
  // not logged in
  {
    path: '/',
    component: () => import('layouts/big-logo'),
    meta: { requiresAuth: false },
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('pages/login'),
        props: (route) => ({ redirect: route.query.redirect }),
        meta: { ifAuthGoHome: true },
      },
      {
        path: 'account-activatie/:token',
        name: 'account-activation',
        component: () => import('pages/user-activate'),
        props: (route) => ({ token: route.params.token }),
        beforeEnter: (to, from, next) => {
          localStore.dispatch('user/logout')
          next()
        },
      },
      {
        path: 'wachtwoord-reset',
        name: 'password-forget',
        component: () => import('pages/password-forget'),
        meta: { ifAuthGoHome: true },
      },
      {
        path: 'wachtwoord-reset/:token',
        name: 'password-reset',
        component: () => import('pages/password-reset'),
        meta: { ifAuthGoHome: false },
        props: (route) => ({ token: route.params.token }),
      },
      {
        path: 'logout',
        name: 'logout',
        beforeEnter: (to, from, next) => {
          if (localStore) {
            localStore.dispatch('user/logout')
          }
          next('/login')
        },
      },
    ],
  },
  // logged in special pages without distracting menu's
  {
    path: '/',
    component: () => import('layouts/no-menu'),
    meta: { requiresAuth: true },
    children: [
      {
        path: 'methodiek',
        name: 'user-survey',
        component: () => import('pages/user-survey'),
        meta: { title: 'Dienstevaluatie', requiresProfile: true },
      },
    ],
  },
  // logged in
  {
    path: '/',
    component: () => import('layouts/default'),
    meta: { requiresAuth: true },
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('pages/user-home'),
        meta: { title: 'Welkom' },
      },
      {
        path: 'dashboard-selecteren',
        name: 'dashboard-select',
        component: () => import('pages/user-dashboard-select'),
        meta: { title: 'Dashboard selecteren' },
      },
      {
        path: 'dashboard',
        name: 'dashboard-default',
        component: () => import('pages/user-dashboard'),
        meta: { title: 'Dashboard' },
      },
      {
        path: 'dashboard/:id',
        name: 'dashboard',
        component: () => import('pages/user-dashboard'),
        meta: { title: 'Dashboard' },
        props: (route) => ({
          id: route.params.id,
          fixedFilters: JSON.parse(route.query.filters),
        }),
      },
      {
        path: 'antwoorden',
        name: 'answers',
        component: () => import('pages/answers'),
        meta: { title: 'Antwoorden' },
      },
      {
        path: 'profiel',
        name: 'user-profile',
        component: () => import('pages/user-profile'),
        meta: { title: 'Profiel' },
      },
      {
        path: 'wachtwoord-wijzigen',
        name: 'user-password',
        component: () => import('pages/user-password'),
        meta: { title: 'Wachtwoord wijzigen' },
      },
      {
        path: 'offline',
        name: 'offline',
        component: () => import('pages/offline'),
        meta: { title: 'Kan geen verbinding maken', requiresProfile: false },
      },
      {
        path: 'over-tipper',
        name: 'about',
        component: () => import('pages/about'),
        meta: { title: 'Over Tipper', requiresProfile: false },
      },
      {
        path: 'voorwaarden',
        name: 'terms',
        component: () => import('pages/terms'),
        meta: { title: 'Gebruikersvoorwaarden', requiresProfile: false },
      },
      {
        path: 'privacy-verklaring',
        name: 'privacy-statement',
        component: () => import('pages/privacy-statement'),
        meta: { title: 'Privacyverklaring', requiresProfile: false },
      },
    ],
  },
  {
    // pagina's voor organisatiebeheerders
    path: '/organisatie',
    name: 'organisation',
    component: () => import('layouts/default'),
    meta: { requiresAuth: true },
    children: [
      {
        path: 'gebruikers',
        name: 'organisation-users',
        component: () => import('pages/organisation-users'),
        meta: { title: 'Gebruikersbeheer' },
      },
      {
        path: 'gebruikers-importeren',
        name: 'organisation-user-import',
        component: () => import('pages/organisation-user-import'),
        meta: { title: 'Gebruikers importeren' },
      },
      {
        path: 'teams',
        name: 'organisation-departments',
        component: () => import('pages/organisation-departments'),
        meta: { title: 'Teamsbeheer' },
      },
      {
        path: 'meetronden',
        name: 'organisation-rounds',
        component: () => import('pages/organisation-rounds'),
        meta: { title: 'Meetronden' },
      },
      {
        path: 'diensttypen',
        name: 'organisation-shift-types',
        component: () => import('pages/organisation-shift-types'),
        meta: { title: 'Diensttypen' },
      },
    ],
  },
  {
    // pagina's voor accuralis
    path: '/accuralis',
    component: () => import('layouts/default'),
    meta: { requiresAuth: true },
    children: [
      {
        path: 'organisaties',
        name: 'organisations',
        component: () => import('pages/organisations'),
        meta: { title: 'Organisatiebeheer' },
      },
      {
        path: 'methodieken',
        name: 'surveys',
        component: () => import('pages/surveys'),
        meta: { title: 'Methodiekenbeheer' },
      },
      {
        path: 'methodiek/:id',
        name: 'survey-edit',
        component: () => import('pages/survey-edit'),
        meta: { title: 'Methodiek wijzigen' },
        props(route) {
          const props = { ...route.params }
          // convert id to number
          props.id = parseInt(props.id)
          return props
        },
      },
      {
        path: 'methodiek-importeren/:id',
        name: 'user-survey-import',
        component: () => import('pages/user-survey-import'),
        meta: { title: 'Methodiek dienstevaluaties importeren' },
        props(route) {
          const props = { ...route.params }
          // convert id to number
          props.id = parseInt(props.id)
          return props
        },
      },
      {
        path: 'indicatordefinitie/:surveyId/:id?',
        name: 'indicator-edit',
        component: () => import('pages/indicator-edit'),
        meta: { title: 'Definitie indicator wijzigen' },
        props(route) {
          const props = { ...route.params }
          // convert id to number
          props.surveyId = parseInt(props.surveyId)
          if (props.id) {
            // add a new one
            props.id = parseInt(props.id)
          }
          return props
        },
      },
      {
        path: 'vragen/:surveyId',
        name: 'questions',
        component: () => import('pages/questions'),
        meta: { title: 'Vragen wijzigen' },
        props(route) {
          const props = { ...route.params }
          props.surveyId = parseInt(props.surveyId, 10)
          return props
        },
      },
      {
        path: 'vraag/:surveyId/:id',
        name: 'question-edit',
        component: () => import('pages/question-edit'),
        meta: { title: 'Vraag wijzigen' },
        props(route) {
          const props = { ...route.params }
          // convert id to number
          props.surveyId = parseInt(props.surveyId)
          props.id = parseInt(props.id)
          return props
        },
      },
    ],
  },

  { // Always leave this as last one
    path: '*',
    component: () => import('pages/404'),
  },
]
