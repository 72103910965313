import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import VueApollo from 'vue-apollo'
import { createHttpLink } from 'apollo-link-http'
import { Loading, Notify, QSpinnerCube } from 'quasar'
import { RetryLink } from 'apollo-link-retry'
import { logErrorMessages } from '@vue/apollo-util'
import { TokenRefreshLink } from 'apollo-link-token-refresh'

import { getToken, setToken } from '../store/user'
//import router from '../router'

const GRAPHQL_URI = process.env.GRAPHQL_URI.replaceAll('"', '')

const httpLink = createHttpLink(
  {
    uri: GRAPHQL_URI,
    credentials: 'include',
  },
)

console.log('Ik ga verbinden met: ' + GRAPHQL_URI)

const isTokenExpired = function(token) {
  return token == null || token.jwtExpiryDate == null || token.jwtExpiryDate < new Date()
}

const authLink = new TokenRefreshLink({
  accessTokenField: 'refreshToken',
  isTokenValidOrUndefined: () => {
    const token = getToken()
    return !isTokenExpired(token) || token == null || typeof token.refreshToken !== 'string'
  },
  fetchAccessToken: () => {
    //https://github.com/newsiberian/apollo-link-token-refresh/issues/31
    return fetch(GRAPHQL_URI, {
      method: 'POST',
      credentials: 'include',
      headers: {
        accept: '*/*',
        'content-type': 'application/json',
        refresh_token: getToken().refreshToken,
      },
      body:
        JSON.stringify({
          query: `
            mutation {
              refreshToken {
                userId
                jwtExpirationInS
                refreshToken
                refreshTokenExpirationInS
              }
            }`,
        }),
    })
  },
  handleFetch: accessToken => {
    setToken(accessToken)
    console.log(accessToken)
  },
  handleResponse: (operation, accessTokenField) => response => response.text()
    .then(JSON.parse)
    .then(res => res.data),
  handleError: err => {
    console.warn('Invalid refresh token')
    console.error(err)
    if (!window.location.href.includes('/logout') && !window.location.href.includes('/login')) {
      window.location.href = '/logout'
    }
  },
})

// add retrylink
const retryIf = (error, operation) => {
  const doNotRetryCodes = [500, 400, 401]
  console.log('retry 😫', error)
  return !!error && !doNotRetryCodes.includes(error.statusCode)
}

const retryLink = new RetryLink({
  delay: {
    initial: 100,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 10,
    retryIf,
  },
})

const link = authLink.concat(retryLink).concat(httpLink)

let loadingCounter = 0

// Create the apollo client
const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache({
    typePolicies: {
      // add keys for object that don't have id as a primairy key
      UserDepartment: {
        keyFields: ['userId', 'departmentId'],
      },
      ValidationMessage: {
        keyFields: ['key'],
      },
      YearOrDate: {
        keyFields: ['year', 'exactDate'],
      },
      Period: {
        keyFields: ['dateFrom', 'dateTo'],
      },
      Result: {
        keyFields: ['indicatorId'],
      },
      // TODO ResultValue, VariableValue --> add random generated id's in backend --> nanoid
    },
  }),
  connectToDevTools: true,
})

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  watchLoading (isLoading, countModifier, smartQuery) {
    if (smartQuery.loadingKey) {
      return
    }
    if (loadingCounter === 0) {
      Loading.show({ spinner: QSpinnerCube, delay: 1000 })
    }
    loadingCounter += countModifier
    if (!isLoading) {
      loadingCounter = 0
      Loading.hide()
    }
  },
  // NOTE: I did 5 attempts to put the error handler in a seperate ES6 module but I can't get the router available
  errorHandler({ graphQLErrors, networkError }) {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        let userMessage = ''
        let handleGlobally = true
        // global error handling
        switch (message) {
          case 'Blocked':
            userMessage = 'U heeft helaas geen toegang meer tot Tipper. Neem contact op met uw leidinggevende voor meer informatie.'
            break
          case 'OrganisationNotActive':
            userMessage = 'Uw organisatie maakt geen gebruik meer van Tipper.'
            break
          case 'UserNotAuthenticated':
            userMessage = 'Uw sessie is verlopen. Log a.u.b. opnieuw in...'
            break
          default:
            handleGlobally = false
        }

        if (handleGlobally) {
          if (userMessage != null) {
            Notify.create({ type: 'warning', message: userMessage, timeout: 0, closeBtn: 'OK' })
          }
          this.$router.push('/logout')
        } else {
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          )
        }
      })

      if (process.env.NODE_ENV !== 'production') {
        logErrorMessages(graphQLErrors)
      }
    }

    if (networkError) {
      let handled = false

      if (networkError.result) {
        const result = networkError.result

        if (result.error) {
          if (result.message === 'UnauthorizedError: jwt expired') {
            Notify.create({
              type: 'info',
              message: 'Uw sessie is verlopen. Log a.u.b. opnieuw in.',
            })
            this.$router.push('/logout')
            handled = true
          }
        }
      }

      if (!handled) {
        // Notify.create({ type: 'negative', message: 'Kan de pagina niet laden. Controleer uw internetverbinding...' })
        console.log(`[Network error]: ${networkError}`)
        //this.$router.push('/offline')
      }
    }
  },
})

export default ({ app, Vue }) => {
  Vue.use(VueApollo)
  app.apolloProvider = apolloProvider
}