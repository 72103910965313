import { mapGetters, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'isSmallScreen',
      'isBigScreen',
      'maxGraphValues',
    ]),
  },

  methods: {
    ...mapMutations(['setWindowWidth']),

    onResize() {
      this.setWindowWidth(window.innerWidth)
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
}