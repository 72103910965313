<template>
  <div id="q-app">
    <router-view />
  </div>
</template>

<script>
import screenSizeMixin from './mixins/screen-size'

export default {
  name: 'App',
  mixins: [screenSizeMixin],
}
</script>

<style lang="stylus">
  .q-btn.disabled {
      opacity: 0.4 !important;
  }

  a:not(.q-item, .q-tab, .q-btn) {
    &:link {
      color: $secondary;
    }

    &:visited {
      color: $accent;
    }

    &:hover {
      text-decoration-color: $accent;
      text-decoration-style: double;
    }

    &:active {
      background: transparentify($secondary, 0.1);
      text-decoration-color: $accent;
    }
  }

  // change the active color from default primary to secondary because primary is black in Tipper
  .q-item.q-router-link--active, .q-item--active {
    color: var(--q-color-secondary);
  }

  @media (hover: hover) and (pointer: fine) {
    // actieknoppen alleen tonen bij hover over rij
    .q-tr .row-action-buttons {
      visibility: hidden;
    }
    .q-tr:hover .row-action-buttons {
      visibility: visible;
    }
  }

  @media screen and (max-width: 600px) {
    .q-if {
      font-size: 0.9rem;
    }
  }

  @media screen and (max-width: 400px) {
    .q-if {
      font-size: 0.8rem;
    }
  }

  @media print {
    body {
      width: 100%;

      .q-layout {
        // prevent empty print pages
        min-height: auto !important; /* important to overwrite inline-style */
      }

      .q-page-container {
        padding: 3rem !important;

        .q-page {
          // prevent empty print pages
          min-height: auto !important; /* important to overwrite inline-style */
        }
      }

      header, .q-notifications, .q-drawer, .q-tabs, .q-btn, .q-table-bottom-item, .q-tool-tip, .q-menu {
        display: none;
      }

      .q-table-control {
        .q-select {
          display : none;
        }
      }
    }
  }

  // on screen keyboard view: hide header and footer
  @media only screen and (max-height: 400px) {
    header:not(.never-hide), footer:not(.never-hide) {
      display: none;
      height: 0;
      transition: ease all .5s;
    }
  }
</style>
