import Vue from 'vue'
import VueRouter from 'vue-router'
import { Notify } from 'quasar'

import routes from './routes'

// Suppress navigation failure errors
// Because we want to be able to redirect the user to the login screen if he/she is logged out
// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) { return originalPush.call(this, location, onResolve, onReject) }
  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}

Vue.use(VueRouter)

import store from '../store/index'

const Router = new VueRouter({
  /*
   * NOTE! Change Vue Router mode from quasar.conf.js -> build -> vueRouterMode
   *
   * When going with "history" mode, please also make sure "build.publicPath"
   * is set to something other than an empty string.
   * Example: '/' instead of ''
   */

  // Leave as is and change from quasar.conf.js instead!
  mode: process.env.VUE_ROUTER_MODE,
  base: process.env.VUE_ROUTER_BASE,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        el: to.hash,
        behavior: 'smooth',
      }
    }
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes,
})

const localStore = store

Router.beforeEach((to, from, next) => {
  // requiresAuth
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!localStore.getters['user/user']) {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
  // ifAuthGoHome
  if (to.matched.some(record => record.meta.ifAuthGoHome)) {
    // this route requires auth, check if logged in
    if (localStore.getters['user/user'] !== null) {
      // Notify.create({ type: 'warning', message: 'U bent al ingelogd.' })
      next('/home')
    } else {
      next()
    }
  }
  // requiresPorfile
  if (to.matched.some(record => record.meta.requiresProfile)) {
    const user = localStore.getters['user/user']
    if (user !== null && user.hasValidProfile) {
      next()
    } else {
      if (user !== null) {
        // no profile yet --> redirect to profile
        Notify.create({
          type: 'info',
          timeout: 10000,
          closeBtn: 'Sluiten',
          message: 'U heeft uw profiel nog niet (volledig genoeg) ingevuld. Vul a.u.b. eerst uw profiel in. Het profiel kan altijd later nog aangevuld worden.',
        })
        next('/profiel')
      }
    }
  }
})

export default Router
