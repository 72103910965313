import { date } from 'quasar'
import gql from 'graphql-tag'
import { apolloProvider } from '../boot/apollo'
const { addToDate } = date

// REFACTOR: Het is de vraag of het nuttig is dat hier vuex voor gebruikt wordt

import {
  AUTH_USER_ID_LS_KEY, AUTH_REFRESH_TOKEN_LS_KEY, AUTH_USER_TYPE_LS_KEY,
  AUTH_USER_HAS_PROFILE, AUTH_JWT_EXPIRATION_LS_KEY, AUTH_REFRESH_TOKEN_EXPIRATION_LS_KEY,
} from '../constants'

function restoreUser() {
  let user = null
  if (localStorage.getItem(AUTH_USER_ID_LS_KEY) != null) {
    user = {
      id: localStorage.getItem(AUTH_USER_ID_LS_KEY),
      userType: localStorage.getItem(AUTH_USER_TYPE_LS_KEY),
      hasValidProfile: localStorage.getItem(AUTH_USER_HAS_PROFILE) === 'true',
    }
  }
  return user
}

/**
 * @param {*} tokenInfo token info from the backend: NOTE: not in the same format as the token in this store
 */
export function setToken(tokenInfo) {
  localStorage.setItem(AUTH_JWT_EXPIRATION_LS_KEY, addToDate(new Date(), { seconds: tokenInfo.jwtExpirationInS }).getTime())
  localStorage.setItem(AUTH_REFRESH_TOKEN_LS_KEY, tokenInfo.refreshToken)
  localStorage.setItem(AUTH_REFRESH_TOKEN_EXPIRATION_LS_KEY, addToDate(new Date(), { seconds: tokenInfo.refreshTokenExpirationInS }).getTime())
}

export function getToken() {
  if (!localStorage.getItem(AUTH_JWT_EXPIRATION_LS_KEY)) {
    return null
  }
  return {
    jwtExpiryDate: new Date(parseInt(localStorage.getItem(AUTH_JWT_EXPIRATION_LS_KEY))),
    refreshToken: localStorage.getItem(AUTH_REFRESH_TOKEN_LS_KEY),
    refreshTokenExpiryDate: new Date(parseInt(localStorage.getItem(AUTH_REFRESH_TOKEN_EXPIRATION_LS_KEY))),
  }
}

export default {
  namespaced: true,

  state () {
    const user = restoreUser()
    return {
      user,
    }
  },

  getters: {
    user: state => state.user,
    isLoggedIn: state => state.user !== null,
    isAdmin: state => state.user !== null && (state.user.userType === 'ADMIN' ||
                      state.user.userType === 'GLOBAL_ADMIN' ||
                      state.user.userType === 'ORGANISATION_ADMIN'),
    isOrganisationAdmin: state => state.user !== null && (state.user.userType === 'GLOBAL_ADMIN' ||
                                  state.user.userType === 'ORGANISATION_ADMIN'),
    isGlobalAdmin: state => state.user !== null && state.user.userType === 'GLOBAL_ADMIN',
    hasValidProfile: (state) => {
      return state.user !== null && state.user.hasValidProfile
    },
  },

  mutations: {
    updateRefreshToken(state, value) {
      setToken(value)
    },

    setUser (state, value) {
      localStorage.setItem(AUTH_USER_ID_LS_KEY, value.id)
      localStorage.setItem(AUTH_USER_TYPE_LS_KEY, value.userType)
      localStorage.setItem(AUTH_USER_HAS_PROFILE, value.hasValidProfile != null)
      if (value.token) {
        setToken(value.token)
      }
      state.user = value
      state.user.hasValidProfile = value.hasValidProfile != null
    },
    setHasValidProfile(state, value) {
      state.user.hasValidProfile = value
      localStorage.setItem(AUTH_USER_HAS_PROFILE, value)
    },
    clearUser(state) {
      state.user = null
    },
  },

  actions: {
    async logout({ commit, state }) {
      commit('clearUser')
      const apolloClient = apolloProvider.defaultClient
      await apolloClient.mutate({
        mutation: gql`mutation {
          logout
        }`,
      })

      localStorage.removeItem(AUTH_USER_ID_LS_KEY)
      localStorage.removeItem(AUTH_REFRESH_TOKEN_LS_KEY)
      localStorage.removeItem(AUTH_JWT_EXPIRATION_LS_KEY)
      localStorage.removeItem(AUTH_REFRESH_TOKEN_EXPIRATION_LS_KEY)
      localStorage.removeItem(AUTH_USER_TYPE_LS_KEY)
      localStorage.removeItem(AUTH_USER_HAS_PROFILE)

      apolloClient.clearStore()
    },
  },
}
