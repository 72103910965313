
/**
 * For converting a list of selected items to a readable string
 * @param {*} allSelectedText text to return when all items are selected
 * @param {*} selectedValues values that are selected
 * @param {*} totalOptionCount number of options
 * @param {*} toReadableNameFunc function to convert value to readable value
 */
export function selectedItemsToString(allSelectedText, selectedValues, totalOptionCount, toReadableNameFunc) {
  if (typeof selectedValues === 'undefined' ||
    selectedValues.length === 0 ||
    selectedValues.length === totalOptionCount) {
    return allSelectedText
  }

  let name = ''
  let i = 0
  for (const value of selectedValues) {
    try {
      name += toReadableNameFunc(value)
    } catch (err) {
      console.error(err)
    }
    if (i < selectedValues.length - 2) {
      name += ', '
    } else if (i === selectedValues.length - 2) {
      name += ' en '
    }
    i++
  }
  return name
}

export function capitalise(s) {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

/**
 * Email validation based on emailregex.com
 * Replace with simplyfied /\S+@\S+\.\S+/ if it will cause problems in the future
 * @param {String} s
 */
export function isEmail(s) {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(s).toLowerCase())
}

/*
* Dertermine if a string is a number
* https://stackoverflow.com/a/175787/199252
*/
export function isNumeric(str) {
  if (typeof str !== 'string') return false // we only process strings!
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export function strictParseInt(str) {
  if (/^[-+]?(\d+|Infinity)$/.test(str)) {
    return Number(str)
  } else {
    return NaN
  }
}