import { render, staticRenderFns } from "./App.vue?vue&type=template&id=0ce52d01&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"
import style0 from "./App.vue?vue&type=style&index=0&lang=stylus&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QColor from 'quasar/src/components/color/QColor.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import qInstall from "../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QItem,QTab,QColor,QTr,QLayout,QPageContainer,QPage,QDrawer,QTabs,QTable,QMenu,QSelect});
