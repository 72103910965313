import { getSubdomain } from './utils/host'

export const SUBDOMAIN = getSubdomain()
export const PASSWORD_MINIMUM_LENGTH = 10

export const AUTH_USER_ID_LS_KEY = SUBDOMAIN + 'auth_user_id'
export const AUTH_USER_TYPE_LS_KEY = SUBDOMAIN + 'auth_user_type'
export const AUTH_REFRESH_TOKEN_LS_KEY = SUBDOMAIN + 'auth_refresh_token'
export const AUTH_REFRESH_TOKEN_EXPIRATION_LS_KEY = SUBDOMAIN + 'auth_refresh_token_expiration_date'
export const AUTH_JWT_EXPIRATION_LS_KEY = SUBDOMAIN + 'auth_jwt_expiration_date'
export const AUTH_USER_HAS_PROFILE = SUBDOMAIN + 'auth_user_has_profile'

export const SHIFT_TYPE_TIME_PRECISION_IN_MINUTES = 5 // keep aligned with backend
export const MAX_DAYS_OF_RESULTS = 150 // keep aligned with backend

// aantal minuten dat je een uitvraag mag inleveren voordat de dienst is afgelopen
export const SHIFT_SLACK_IN_MINUTES = 60 // keep aligned with backend

export const MIN_REREQUEST_ACCESS_TO_DEPARTMENT_IN_DAYS = 7

export const MIN_WORK_YEAR = new Date().getFullYear() - 90
export const MAX_WORK_YEAR = new Date().getFullYear()

export const MAX_DAYS_AGO_SHIFT_OF_SURVEY = 10