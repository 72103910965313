import periodUtils from '../utils/periods'

export default {
  namespaced: true,

  state() {
    return {
      showFilterDialog: false,
      showPeriodsDialog: false,
      filtersData: null,
      fixedFilters: null,
      periods: {},
      filteredPeriodTypes: ['recent', 'round', 'month', 'week'],
      filteredRoundIds: [],
      filteredYears: [],
      filteredMonths: [],
    }
  },

  getters: {
    showFilterDialog: state => state.showFilterDialog,
    showPeriodsDialog: state => state.showPeriodsDialog,

    departments: (state) => {
      if (state.filtersData) {
        return state.filtersData.relevantDepartments
      }
      return null
    },

    shiftTypes: (state) => {
      if (state.filtersData) {
        return state.filtersData.organisation.shiftTypes.filter(x => x.active)
      }
      return null
    },

    rounds: (state) => {
      if (state.filtersData) {
        return state.filtersData.organisation.rounds
      }
      return null
    },

    activeRoundIds: (state) => {
      if (state.filtersData) {
        return state.filtersData.organisation.activeRounds.map(x => x.id)
      }
      return null
    },

    activeRounds: (state) => {
      if (state.filtersData) {
        return state.filtersData.organisation.activeRounds
      }
    },

    fixedFilters: state => state.fixedFilters,
    periods: state => state.periods,
    filteredPeriodTypes: state => state.filteredPeriodTypes,
    filteredRoundIds: state => state.filteredRoundIds,
    filteredYears: state => state.filteredYears,
    filteredMonths: state => state.filteredMonths,
  },

  mutations: {
    setShowFilterDialog(state, value) {
      state.showFilterDialog = value
    },
    setShowPeriodsDialog(state, value) {
      // eslint-disable-next-line no-prototype-builtins
      if (state.fixedFilters && state.fixedFilters.hasOwnProperty('periods')) {
        state.showPeriodsDialog = false
      } else {
        state.showPeriodsDialog = value
      }
    },

    setFiltersData(state, value) {
      state.filtersData = value
    },

    setFixedFilters(state, value) {
      state.fixedFilters = value
    },

    setPeriods(state, value) {
      state.periods = value
    },

    setFilteredPeriodTypes(state, value) {
      state.filteredPeriodTypes = value
    },

    setFilteredRoundIds(state, value) {
      state.filteredRoundIds = value
    },
    setFilteredYears(state, value) {
      state.filteredYears = value
    },
    setFilteredMonths(state, value) {
      state.filteredMonths = value
    },
  },

  actions: {
    /**
     * Load all possible periods within current rounds
     * @param {*} context
     * @param {*} selectedPeriodKeys optionally add the old selected periods so they can be added if they are missing
     */
    loadPeriods({ commit, state }, selectedPeriodKeys = null) {
      if (!periodUtils.isLoaded()) {
        const rounds = state.filtersData.organisation.rounds
        periodUtils.initPeriods(rounds, selectedPeriodKeys)
      }

      // this version of Vue does not support Maps :(
      const periodsMap = periodUtils.getPeriods()

      commit('setPeriods', Object.fromEntries(periodsMap.entries()))
    },
  },
}